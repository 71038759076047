export {liveLoadData, filterLoad, dataFromForm, submitData, getData, submitDataFile}
import {showMessage} from './message';
import {strToArray} from './helpers';
import {createCookie, checkCookie, readCookie} from './cookie';

// for rendering html result
function liveLoadData($formObj, $fillObj) {
//	console.log('livedata/liveLoadData/formObject', $formObj);
	var action = $formObj.attr('action'),
		actionType = $formObj.data('actionType') === 'submit' ? 'formSubmit' : 'dataSubmit';;
	console.log('liveLoadData::action', action);
	switch(actionType){
		case 'formSubmit' : case 'submit' : $formObj.submit(); break;
		default : submitData(dataFromForm($formObj), action, $fillObj, 'html');
	}
}

function filterLoad($formObj, $fillObj) {
	var action = $fillObj.data('action');
//	console.log('filterLoad::action', action);
	submitData(dataFromForm($formObj), action, $fillObj);
}

function dataFromForm($formObj, asJson = 1) {
	var type = $formObj.prop('nodeName');
	if (type !== 'FORM') {
		var formData = $formObj.closest('form').serializeArray();
	} else {
		var formData = $formObj.serializeArray();
	}
//	console.log('dataFromForm formData', formData);

	var data = {};
	if (jQuery.isArray(formData)) {
		$(formData).each(function (index, obj) {
			var nameRaw = obj.name,
			    name = nameRaw.replace('[]', '[' + index + ']');
			data[name] = obj.value;
		});
	}
	console.log('dataFromForm', data);
	return asJson ? JSON.stringify(data) : data;
}

// the same as axios for vue, but only for data - without sending files
function submitData(data, action, fill = null, typeFill = 'html', $formFind = null, $fillFind = null, $beforeMessage=null) {
	console.log('submitData POST', {
		data: data, 
		action: action, 
		fill: fill, 
		typeFill: typeFill,
		'$formFind': $formFind,
		'$fillFind': $fillFind,
		'$beforeMessage': $beforeMessage
	});
	$.post(action, data, function (response) {
		// return
		try {
			// response is json
			var o = jQuery.parseJSON(response);
			console.log('RESPONSE JSON');
		} catch (error) {
			var o = response;
			console.log('RESPONSE NoJSON');
		}
		
//		console.log(o);
		if (fill === 'redirect'){ 
			console.log('o.success', o.success);
			console.log('o.url', o.url);
			if(o.success !== undefined && o.success && o.url !== undefined){
				window.location.href = o.url;
			}
		}

		// zwracany przez kontroler php content, komunikaty i opcjonalnie fill oraz typeFill
		var content = '';
		var f = fill;
		var tf = typeFill;
		if (typeof o === 'string' || typeof o === 'number') {
			content = o; // string
		} else {
			// obiekt musi zawierać klucz o nazwie content z zawartością
			if (o.hasOwnProperty('content')) {
				content = o.content;
			}
			if (o.hasOwnProperty('fill')) {
				f = o.fill;
			}
			if (o.hasOwnProperty('typeFill')) {
				tf = o.typeFill;
			}
			////////// messages //////////
			// gdzie wyświetlić
			var messageTo = '';
			if($beforeMessage !== null){
				if($beforeMessage instanceof jQuery){
					messageTo = $beforeMessage;
				}else{
					messageTo = $($beforeMessage);
				}
			}else if($fillFind !== null){
				messageTo = $fillFind;
			}else{
				var mTo = $('body').find('.container-content');
				if(mTo !== undefined){
					messageTo = mTo;
				}
			}
			// pojedynczy komunikat
			if (o.hasOwnProperty('message') && o.message) {
				showMessage(o.message, o.status, messageTo);
			}
			// kilka komunikatów (w array'u)
			if (o.hasOwnProperty('messages') && $.isArray(o.messages) && o.messages) {
				$.each(o.messages, function(i, msg){
					showMessage(msg.message, msg.status, messageTo);
				});
			}
			// stare
			if (o.hasOwnProperty('alert') && o.alert) {
				showMessage(o.alert, 'alert', messageTo);
			}
			// stare
			if (o.hasOwnProperty('error') && o.error) {
				showMessage(o.error, 'error', messageTo);
			}
		}
		// dodanie odpowiedzi do obiektu
		var $ob = {};
		if (f !== null) {
			if (f instanceof jQuery) {
				$ob = f;
			} else {
				$ob = $('body').find(f);
			}
			switch(tf){
				case 'val':
				case 'value':
					$ob.val(content);
					break;
				case 'check':
				case 'checked':
					$ob.attr('checked', content);
					break;
				case 'select':
				case 'selected':
					$ob.attr('selected', content);
					break;
				case 'number':
				case 'text':
				case 'txt' :
					$ob.text(content);
					console.log('$ob.text(content):', content.replace(/(?:\r\n|\r|\n|\t)/g,'').slice(0,50));
					break;
				case 'html':
					$ob.html(content);
					console.log('$ob.html(content):', content.replace(/(?:\r\n|\r|\n|\t)/g,'').slice(0,100));
					break;
				case 'show':
					$ob.show(700);
					break;
				case 'hide':
					$ob.hide(700);
					break;
				default :
					showMessage('Data "typeFill" ' + tf + ' is unknown type!', 'danger');
			}
		}
		if ($formFind !== null && $fillFind !== null) {
			liveLoadData($formFind, $fillFind);
		}
	});
}

function submitDataFile($form, action, fill = null, typeFill = 'html', $beforeMessage=null) {
	console.log('submitData FILE', {
		'$form': $form, 
		action: action, 
		fill: fill, 
		typeFill: typeFill,
		'$beforeMessage': $beforeMessage
	});

	$.ajax({
		url: action,
		data: new FormData($form),
		type: "post",
		contentType: false,
		processData: false,
		cache: false,
		dataType: "json",
		error: function (err) {
			console.error(err);
		},
		success: function (data) {
//			console.log(data);
			try {
				// response is json
				var o = jQuery.parseJSON(data);
				console.log('RESPONSE JSON', o);
			} catch (error) {
				var o = data;
				console.log('RESPONSE NoJSON', o);
			}
			// zwracany przez kontroler php content, komunikaty i opcjonalnie fill oraz typeFill
			var content = '';
			var f = fill;
			var tf = typeFill;
			if (typeof o === 'string' || typeof o === 'number') {
				content = o; // string
			} else {
				// obiekt musi zawierać klucz o nazwie content z zawartością
				if (o.hasOwnProperty('content')) {
					content = o.content;
				}
				if (o.hasOwnProperty('fill')) {
					f = o.fill;
				}
				if (o.hasOwnProperty('typeFill')) {
					tf = o.typeFill;
				}
				// messages
				var messageTo = '';
				if($beforeMessage !== null){
					if($beforeMessage instanceof jQuery){
						messageTo = $beforeMessage;
					}else{
						messageTo = $($beforeMessage);
					}
				}else{
					var  mTo = $('body').find('.container-content');
					if(mTo !== undefined){
						messageTo = mTo;
					}
				}
				if (o.hasOwnProperty('message') && o.message) {
					if (o.hasOwnProperty('status')){
						showMessage(o.message, o.status, messageTo);
					}else{
						showMessage(o.message, 'info', messageTo);
					}
				}
				if (o.hasOwnProperty('messages') && $.isArray(o.messages) && o.messages) {
					$.each(o.messages, function(i, msg){
						showMessage(msg.message, msg.status, messageTo);
					});
				}
				if (o.hasOwnProperty('alert') && o.alert) {
					showMessage(o.alert, 'alert', messageTo);
				}
				if (o.hasOwnProperty('error') && o.error) {
					showMessage(o.error, 'error', messageTo);
				}
			}
			// dodanie odpowiedzi do obiektu
			var $ob = {};
			if (f !== null) {
				if (f instanceof jQuery) {
					$ob = f;
				} else {
					$ob = $(f);
				}
				if (tf === 'val' || tf === 'value') {
					$ob.val(content);
				} else if (tf === 'checked') {
					$ob.attr('checked', content);
				} else if (tf === 'number' || tf === 'text' || tf === 'txt') {
					$ob.text(content);
					console.log(content.replace(/(?:\r\n|\r|\n|\t)/g,'').slice(0,50));
				} else if (tf === 'html') {
					$ob.html(content);
					console.log(content.replace(/(?:\r\n|\r|\n|\t)/g,'').slice(0,100));
				} else if (tf === 'show') {
					$ob.show(700);
				} else if (tf === 'hide') {
					$ob.hide(700);
				} else {
					showMessage('Data "typeFill" ' + tf + ' is unknown type!', 'error', 'main');
				}
			}
			
		},
		complete: function () {
			console.log("Request upload finished.");
		}
	});
}


// the same as axios for vue, but only for data - without sending files
function getData(action, fill = null, typeFill = 'html', $formFind = null, $fillFind = null, $beforeMessage=null) {
	console.log('submitData GET', {
		action: action, 
		fill: fill, 
		typeFill: typeFill,
		'$formFind': $formFind,
		'$fillFind': $fillFind,
		'$beforeMessage': $beforeMessage
	});
	$.get(action, function (response) {
		// return
		try {
			// response is json
			var o = jQuery.parseJSON(response);
			console.log('RESPONSE JSON', o);
			if (o === true && $formFind !== null && $fillFind !== null) {
				// jeśli odpowiedź dla get jest dokładnie "true" i podano formularz + miejsce zwrotu dla niego
				liveLoadData($formFind, $fillFind);
				return false;
			}
		} catch (error) {
			var o = response;
			console.log('RESPONSE NoJSON', o);
		}
		// zwracany przez kontroler php content, komunikaty i opcjonalnie fill oraz typeFill
		var content = '';
		var f = fill;
		var tf = typeFill;
		if (typeof o === 'string' || typeof o === 'number') {
			content = o; // string
		} else {
			// obiekt musi zawierać klucz o nazwie content z zawartością
			if (o.hasOwnProperty('content')) {
				content = o.content;
			}
			if (o.hasOwnProperty('fill')) {
				f = o.fill;
			}
			if (o.hasOwnProperty('typeFill')) {
				tf = o.typeFill;
			}
			// messages
			var messageTo = '';
			if($beforeMessage !== null){
				if($beforeMessage instanceof jQuery){
					messageTo = $beforeMessage;
				}else{
					messageTo = $($beforeMessage);
				}
			}else if($fillFind !== null){
				messageTo = $fillFind;
			}
			if (o.hasOwnProperty('message') && o.message) {
				if (o.hasOwnProperty('status')){
					showMessage(o.message, o.status, messageTo);
				}else{
					showMessage(o.message, 'info', messageTo);
				}
			}else if($fillFind !== null){
				messageTo = $fillFind;
			}else{
				var mTo = $('body').find('.container-content');
				if(mTo !== undefined){
					messageTo = mTo;
				}
			}
			if (o.hasOwnProperty('messages') && $.isArray(o.messages) && o.messages) {
				$.each(o.messages, function(i, msg){
					showMessage(msg.message, msg.status, messageTo);
				});
			}
			if (o.hasOwnProperty('alert') && o.alert) {
				showMessage(o.alert, 'alert', messageTo);
			}
			if (o.hasOwnProperty('error') && o.error) {
				showMessage(o.error, 'error', messageTo);
			}
		}
		// dodanie odpowiedzi do obiektu
		var $ob = {};
		if (f !== null) {
			if (f instanceof jQuery) {
				$ob = f;
			} else {
				$ob = $(f);
			}
			if (tf === 'val' || tf === 'value') {
				$ob.val(content);
			} else if (tf === 'checked') {
				$ob.attr('checked', content);
			} else if (tf === 'number' || tf === 'text' || tf === 'txt') {
				$ob.text(content);
				console.log(content.replace(/(?:\r\n|\r|\n|\t)/g,'').slice(0,50));
			} else if (tf === 'html') {
				$ob.html(content);
				console.log(content.replace(/(?:\r\n|\r|\n|\t)/g,'').slice(0,100));
			} else if (tf === 'show') {
				$ob.show(700);
			} else if (tf === 'hide') {
				$ob.hide(700);
			} else {
				showMessage('Data "typeFill" ' + tf + ' is unknown type!', 'error', 'main');
			}
		} else {
			return content;
		}
	});
}


