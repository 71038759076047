export {showMessage, hideMessage, beforeAlert, beforeAlertRemove}

function showMessage(message, classM='info', beforeBlock='') {

	if(classM === undefined){
		classM = 'info';
	}
	var cls = 'alert alert-' + classM + ' alert-dismissible fade show mb-2',
		clx = '.alert.alert-' + classM + '.alert-dismissible.fade.show',
		clt = '<span aria-hidden="true">&times;</span>',
		clo = '<button type="button" class="close bg-trans" data-dismiss="alert" aria-label="Close">' + clt + '</button>',
		msg = '<div class="'+cls+'" role="alert">' + message + clo + '</div>';

	if(beforeBlock === undefined || !beforeBlock.length){
		$('#alerts').prepend(msg);
	}else{
		var $obj = {};
		if(typeof beforeBlock === 'string'){
			$obj = $('body').find(beforeBlock);
		}else{
			$obj = beforeBlock;
		}
		if($obj.parent().hasClass('row')){
			msg = '<div class="' + cls + ' col-12" role="alert">' + message + clo + '</div>';
		}
		if($obj.hasClass('container-content')) {
			var $body = $('body'),
				body_id = $body.prop('id'),
				adminPanel = body_id !== undefined && body_id === 'adminPage',
				$msgContainer = !adminPanel ? $body.find('#site-header') : $body.find('#top-nav');
			if(adminPanel){
				if($body.find('#sidebar').length){
					$obj.prepend(msg);
					console.log('container-content prepend');
				} else {
					$msgContainer.append(msg);
					console.log('top-nav append');
				}
			}else if ($msgContainer !== undefined){
				$msgContainer.append(msg);
			}else{
				$obj.before(msg);
			}
		}else{
			$obj.before(msg);
		}
	}
	setTimeout(function(){
		$('body').find(clx).fadeOut(700, function() {
			$(this).remove();
		});
	}, 8000);
}


function hideMessage($btn){
	var $p = $btn.parent();
	$p.fadeOut(700);
	setTimeout(function(){
		$p.remove();
	}, 800);
}

// komunikat dla konkretnych pól formularza
function beforeAlert($object, msg, type='danger'){
	var $group = $object.closest('.form-group');
	if($group === undefined){
		var $group = $object;
	}
	var $alert = $group.find('.alert');
	if($alert !== undefined){
		$alert.remove();
	}
	$group.prepend('<div class="alert alert-' + type + ' alert-dismissible fade show" role="alert">' + msg + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
}

function beforeAlertRemove($object){
	var $group = $object.closest('.form-group');
	if($group === undefined){
		var $group = $object;
	}
	var $alert = $group.find('.alert');
	if($alert !== undefined){
		$alert.remove();
	}
}
