export {getUrlParam, getServerUrl, getSiteUrl, getPathnameElements}
import {array_filter} from './helpers';

$(document).ready(function() {
	console.log('url.js', {
		'getServerUrl()':getServerUrl(), 
		'getSiteUrl()':getSiteUrl(), 
		'getPathnameElements():{count,elements}':getPathnameElements(), 'getUrlParam(nameParam)':'wymaga podania nameParam'});
});

function getUrlParam(nameParam) {
	var paramsPage = decodeURIComponent(window.location.search.substring(1));
	var variables = paramsPage.split('&');
	var response = null;
	$.each(variables, function (index, variable) {
		var itemVars = variable.split('=');
		if (itemVars[0] === nameParam) {
			response = itemVars[1];
		}
	});
	return response;
}

function getServerUrl(){
	var surl = decodeURIComponent(window.location.origin) + '/';
	return surl;
}

function getPathnameElements(){
	var pn = decodeURIComponent(window.location.pathname);
	var $pn = pn.split('/').filter(array_filter);
	return {count:$pn.length, elements:$pn};
}
function getSiteUrl(){
	return decodeURIComponent(window.location.origin) + decodeURIComponent(window.location.pathname);
}
